<template>
  <div class="image-upload-wrap">
    <div class="title-sec">
      <h2>Add Works</h2>
      <p>
        Drag and drog or browse files that you want to add here. Only JPEG &amp;
        PNG files with file size upto 1 MB.
      </p>
    </div>
    <div class="dropzone-block">
      <div id="preview-container">
        <div id="preview-item-container"></div>
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          v-show="true"
        />
        <!-- i have just toggle the visibility via css classes, please change it to vue states! -->
        <!-- show a new button when max-limit reached-->
        <div
          id="proViewToggler"
          v-on:click="openProFeatures()"
          title="click to add more images"
        >
          <span></span>
        </div>
        <!-- show the modal on click of toggler-->
        <div v-show="showProFeatures">
          <div class="pro-features-block">
            <div class="pro-features-wrap">
              <div class="pro-close" v-on:click="closeProFeatures()">
                <img alt="close" src="@/assets/images/icon-close.svg" />
              </div>
              <UploadPageProFeaturesView />
            </div>
          </div>
        </div>
        <!-- TODO: we can add other types of cards here -->
      </div>
    </div>
    <div class="btn-wrap">
      <button type="button" class="btn-cancel">Cancel</button>
      <button type="button" class="btn-advance" v-on:click="updateFolio()">
        Looks Good. Proceed
      </button>
    </div>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone-vue3";
import uploadFileIcon from "./../../assets/images/upload-file-icon.svg";
import UploadPageProFeaturesView from "./../NewUser/UploadPageProFeaturesView.vue";

export default {
  name: "UploadImage",

  components: {
    vueDropzone,
    UploadPageProFeaturesView,
  },
  data() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        keepLocal: true,
        dictDefaultMessage: `<img src='${uploadFileIcon}' alt='upload' />`,
        acceptedFiles: "image/*",
        thumbnailWidth: "300",
        thumbnailHeight: "225",
        uploadMultiple: false,
        previewsContainer: "#preview-item-container",
        addRemoveLinks: true,
        maxFiles: 8,
        maxFilesize: 6,
        url: this.uploadFiles,
      },
      showProFeatures: false,
    };
  },
  computed: {
    canShowProFeaturesView() {
      return true;
    },
  },
  methods: {
    uploadFiles(file) {
      console.log("uploadFiles");
    },
    openProFeatures() {
      this.showProFeatures = true;
    },
    closeProFeatures() {
      this.showProFeatures = false;
    },
  },
};
</script>

<style lang="scss">
.image-upload-wrap {
  width: 100%;
  color: #ffffff;
}

.title-sec {
  text-align: center;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;

  h2 {
    font-weight: 900;
    font-size: 30px;
    line-height: 1.1;
    margin: 0 0 20px;
    text-align: center;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    margin: 0 0 15px;
  }
}

.dropzone-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

#preview-container {
  display: flex;
  overflow-x: auto;
  padding: 12px 0;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #333;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #666;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

#preview-item-container {
  display: flex;
}

.dz-image {
  width: 300px;
  height: 225px;
  margin: 0;
  background: #303030;
  border: 1.2px dashed #6a6a6a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dz-progress {
  top: 0px;
  right: 0px;
  left: 0px;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  display: none;
  background-color: var(--primary-element-color);
}

.dz-details {
  display: none;
}

.dz-image img {
  border-radius: 10px;
}

.vue-dropzone {
  width: 300px;
  height: 225px;
  border-radius: 10px;
  background: #424242;
  font-family: inherit;
  aspect-ratio: 1.5;
  max-width: calc(100% - 30px);
  max-height: calc(100vh - 200px);
  margin: 0 auto;
  margin-left: 6px;
  padding: 30px;
  border: 0;
  outline: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 1.5px dashed #bfbfbf;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px;
    z-index: 2;
  }

  > .dz-preview .dz-image {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.dropzone .dz-preview {
  margin: 0 !important;
  background: transparent !important;
}

.dz-preview {
  position: relative;
  margin: 0 6px;
  // .dz-progress {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: #ff0;
  //   top: 0;
  //   left: 0;
  // }

  .dz-success-mark {
    display: none;
  }

  .dz-error-mark {
    display: none;
  }

  .dz-remove {
    content: url("~@/assets/images/close-popup.svg");

    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
    text-decoration: none;
  }
}

.btn-wrap {
  margin-top: 40px;
  padding: 10px 0;
  text-align: right;

  button {
    background: var(--primary-cta-background);
    padding: 15px 30px;
    color: var(--primary-cta-color);
    border-radius: 5px;
    border: 0;
    outline: 0;
    font-size: 16px;
    cursor: pointer;

    &.btn-cancel {
      background: #8d8d8d;
      margin-right: 24px;
      color: #fff;

      &:hover {
        background: #676767;
      }
    }

    &.btn-advance {
      background: #f0ff64;

      &:hover {
        background: #ff0;
      }
    }
  }
}

.dz-preview.dz-error.dz-image-preview {
  display: none;
}

div#dropzone.dz-max-files-reached {
  display: none;
}
#proViewToggler {
  display: none;
  align-items: center;
  background: #272727;
  border-radius: 10px;
  padding: 30px;
  cursor: pointer;
  &:hover {
    background: #222;
    span {
      opacity: 0.8;
    }
  }
  span {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url(../../assets/images/icon-pro-toggler.svg) no-repeat center;
    background-size: contain;
  }
}
.dz-max-files-reached + #proViewToggler {
  display: flex;
}
</style>
