<template>
  <!-- pro features -->
  <div class="pro-top-sec">
    <img
      class="pro-icon"
      alt="go pro"
      src="@/assets/images/icon-pro-toggler.svg"
    />
    <h3>Go Pro</h3>
    <p>Switch to <span>Glims Pro</span> to add more shots.</p>
    <ul class="pro-feature-list">
      <li>
        <div class="list-icon">
          <img alt="go pro" src="@/assets/images/feature-1.svg" />
        </div>
        <h4>Showcase more</h4>
        <p>Showcase most of your works. Add upto 30 shots per month.</p>
      </li>
      <li>
        <div class="list-icon">
          <img alt="go pro" src="@/assets/images/feature-2.svg" />
        </div>
        <h4>Tell a short story.</h4>
        <p>Showcase most of your works. Add upto 30 shots per month.</p>
      </li>
      <li>
        <div class="list-icon">
          <img alt="go pro" src="@/assets/images/feature-3.svg" />
        </div>
        <h4>Remove Branding</h4>
        <p>Showcase most of your works. Add upto 30 shots per month.</p>
      </li>
      <li>
        <div class="list-icon">
          <img alt="go pro" src="@/assets/images/feature-4.svg" />
        </div>
        <h4>Supporting 2 Indies</h4>
        <p>You are supporting 2 indie makers from 🇮🇳 made with 🤍.</p>
      </li>
    </ul>
  </div>

  <div class="pro-plans">
    <div class="plan-items-wrap">
      <div class="plan-item">
        <div class="save-text">
          Saves <span>33%</span>
          <img alt="save" src="@/assets/images/arrow-blue.svg" />
        </div>
        <input type="radio" id="yearly_plan" name="pro_plans" checked />
        <label for="yearly_plan">
          <span class="plan-type">Yearly Plan</span>
          <span class="plan-price">$8</span>
          <span class="plan-desc">Per month, billed $96 yearly</span>
        </label>
      </div>
      <div class="plan-item">
        <input type="radio" id="quarterly_plan" name="pro_plans" />
        <label for="quarterly_plan">
          <span class="plan-type">Quarterly Plan</span>
          <span class="plan-price">$12</span>
          <span class="plan-desc">Per month, billed $36 quarterly</span>
        </label>
      </div>
    </div>
    <div class="plans-btn">
      <button type="button" name="">Upgrade to Pro</button>
    </div>
  </div>
  <!-- END pro features -->
</template>

<script>
export default {
  name: "UploadPageProFeaturesView",
};
</script>
<style lang="scss">
.pro-features-block {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  padding: 3% 5%;
  background: rgba(0, 0, 0, 0.5);
}

.pro-features-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #272727;
  border-radius: 20px;
  overflow: auto;
  width: 1200px;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  color: #fff;
  .pro-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    cursor: pointer;
    @media (min-width: 841px) {
      right: 40px;
      top: 40px;
    }
  }
  .pro-top-sec {
    text-align: center;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 841px) {
      padding: 40px;
    }
    img.pro-icon {
      display: block;
      margin: 0 auto 15px;
      width: 36px;
      height: 36px;
      @media (min-width: 841px) {
        width: 50px;
        height: 50px;
        margin: 0 auto 20px;
      }
    }
    h3 {
      font-weight: 900;
      font-size: 24px;
      line-height: 1.2;
      margin: 0 0 10px;
      @media (min-width: 841px) {
        font-size: 30px;
      }
    }
    p {
      margin: 0 0 10px;
      font-size: 13px;
      span {
        color: #f0ff64;
      }
      @media (min-width: 841px) {
        font-size: 15px;
      }
    }
  }
  .pro-feature-list {
    margin: 0;
    padding: 20px 0 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;
    @media (min-width: 841px) {
      padding: 40px 0 0;
    }
    li {
      padding: 15px;
      width: 100%;
      @media (min-width: 541px) {
        width: 50%;
      }
      @media (min-width: 841px) {
        width: 25%;
      }
      @media (min-width: 1200px) {
        width: 20%;
      }
    }
    .list-icon {
      height: 24px;
      @media (min-width: 841px) {
        height: 36px;
      }
      img {
        display: block;
        object-fit: contain;
        width: auto;
        height: 24px;
        margin: 0;
        @media (min-width: 841px) {
          height: 36px;
        }
      }
    }
    h4 {
      font-weight: 700;
      font-size: 17px;
      margin: 10px 0;
      @media (min-width: 841px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 12px;
      line-height: 1.2;
      color: #969696;
      margin: 0;
      @media (min-width: 841px) {
        font-size: 14px;
      }
    }
  }
  .pro-plans {
    padding: 20px;
    background: #303030;
    display: flex;
    flex-direction: column;
    @media (min-width: 841px) {
      padding: 40px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  .plan-items-wrap {
    display: flex;
    flex-direction: column;
    @media (min-width: 841px) {
      flex-direction: row;
      align-items: flex-end;
    }
  }
  .plan-item {
    position: relative;
    margin-bottom: 25px;
    @media (min-width: 841px) {
      margin-right: 30px;
      margin-bottom: 0;
    }
    .save-text {
      position: relative;
      display: flex;
      align-items: flex-start;
      font-size: 13px;
      color: #fff;
      padding: 0 0 10px;
      @media (min-width: 841px) {
        font-size: 15px;
      }
      span {
        display: block;
        margin: 0 5px;
        color: #f0ff64;
      }
      img {
        display: block;
      }
    }
    label {
      display: block;
      margin: 0;
      background: #111111;
      color: #c5c5c5;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      padding: 16px;
      border: 1px solid transparent;
      &:hover {
        background: #222;
      }
    }
    input:checked + label {
      border-color: #f0ff64;
      background: url(../../assets/images/icon-yellow-check.svg) no-repeat top
        right #111111;
      background-position: calc(100% - 16px) 16px;
      color: #fff;
    }
    input {
      display: none;
      opacity: 0;
    }
    .plan-type {
      display: block;
      font-weight: 700;
      font-size: 11px;
      line-height: 1.3;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0 0 20px;
      @media (min-width: 841px) {
        font-size: 13px;
      }
    }
    .plan-price {
      display: block;
      font-weight: 900;
      font-size: 36px;
      line-height: 1;
      color: inherit;
      margin: 0 0 10px;
      @media (min-width: 841px) {
        font-size: 50px;
      }
    }
    .plan-desc {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: #969696;
      @media (min-width: 841px) {
        font-size: 14px;
      }
    }
  }
  .plans-btn {
    button {
      padding: 15px 30px;
      color: var(--primary-cta-color);
      border-radius: 5px;
      border: 0;
      outline: 0;
      font-size: 14px;
      cursor: pointer;
      background: #f0ff64;
      @media (min-width: 841px) {
        font-size: 16px;
      }
      &:hover {
        background: #ff0;
      }
    }
  }
}
</style>
